.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#570000, #020221);
  background: linear-gradient(#002353, #00060e);
  
}
.login_cont {
  width: 666px;
  margin: 0 auto;
  background: gray;
  height: 666px;
}
.login_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  background: #fff;
  box-shadow: 0 2px 1px rgb(255, 255, 255);
  position: relative;
}
.input_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 8px;
  
}
.input_container label{
  color: #002353 !important;
}
.input_container input {
  height: 35px;
  min-width: 300px;
  border: 1px solid #002353;
  background: transparent;
  color: #002353;
  padding: 0 10px;
  box-sizing: border-box;
  outline: none;
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_container button {
  min-width: 300px;
  height: 30px;
  border: 1px solid #002353;
  background: #fff;
  color: #002353;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
}
.button_container button:hover {
  transition: all 0.3s;
  border: 1px solid #fff;
  background: #002353;
  color: #fff;
}
.logo{
  width: auto;
  position: absolute;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forget_container {
  width: 300px;
  display: flex;
  justify-content: end;
  font-size: 0.9em;
  color: #002353;
}