body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}*/
/*
fadeInAnimation
*/
.fadeInAnimation {
  animation-name: fadeInAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.login_cont {
  width: 666px;
  margin: 0 auto;
  background: gray;
  height: 666px;
}
.login_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  background: #2c2c2c;
}
.input_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 8px;
}
.input_container input {
  height: 35px;
  min-width: 300px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  outline: none;
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_container button {
  min-width: 300px;
  height: 30px;
  border: 1px solid #2c2c2c;
  background: #fff;
  color: #2c2c2c;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.3s;
}
.button_container button:hover {
  transition: all 0.3s;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}

.create_report {
}
.container {
}

.form_data {
  width: 35.33%;
  display: flex;
  flex-direction: column;
}
.form_data input {
  height: 30px;
  outline: unset;
  box-sizing: border-box;
  padding: 0 5px;
}
.header {
  width: 50px;
  height: 100vh;
  background: rgb(68, 123, 145);
  position: fixed;
  left: 0;
  overflow-x: hidden;
  transition: all 0.3s;
  color: #fff;
  z-index: 999;
}
.header:hover {
  width: 200px;
  transition: all 0.3s;
}
.header a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.linkText {
  white-space: pre;
  position: absolute;
  left: 50px;
}
.header ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.header li {
  list-style: none;
  position: relative;
  height: 50px;
  align-items: center;
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
}
.header li:hover {
  transition: all 0.3s;
  background: rgb(50 92 104);
}
.ico {
  position: relative;
  width: 50px;
  text-align: center;
}

.childrenContainer {
  width: calc(100% - 50px);
  height: 100vh;
  margin-left: auto;
  overflow: hidden;
}

.subHeader {
  width: 100%;
  height: 50px;
  background-color: #335d6e;
  color: #fff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.subHeader a,
.subHeader button {
  width: auto;
  border: 1px solid;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8em;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}
.logo {
  height: 50px !important;
  width: 100%;
  justify-content: center;
  font-size: 0.9em;
}
.logo:hover {
  cursor: pointer;
}
.children {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 30px 25px;
  height: 100%;
  overflow-y: auto;
}
* {
  padding: 0;
  margin: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.report-cointainer {
  width: 100%;
}

.ex-m {
  transition: all 0.3s;
  margin-bottom: 49px;
}

/* input,
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #447b91;
  border-radius: 4px;
  resize: vertical;
  background: transparent;
  outline: none;
  
} */
select, input[type="text"], input[type="email"], input[type="password"]{
  /*min-height: 50px !important;*/
}
input[type="submit"] {
  background-color: #001e48;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

input[type="submit"]:hover {
  background-color: #050220;
  transition: all 0.3s;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  /* max-width: 600px;*/
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.active {
  transition: all 0.3s;
  background: #050220;
}
.form-container {
  width: 24%;
  position: relative;
  display: flex;
  align-items: end;
}
h1,
h2,
h3,
h4,
h5,
h6,
label,
input {
  color: #447b91;
}
.actions {
  width: 100%;
  display: flex;
  gap: 15px;
  font-size: 20px;
  justify-content: center;
}
.actions a {
  margin: 0;
  padding: 0;
  display: flex;
}
svg {
  cursor: pointer;
}
html,
body,
* {
  margin: 0;
  padding: 0;
  font-family: var(--font-monse);
}
.logo {
  justify-content: center;
  background: #fff;
  box-sizing: border-box;
  padding: 5px;
}
.logo img {
  width: 100%;
  transition: all 0.3s;
}

.other-input {
  position: relative;
  margin: 0;
  width: 100%;
  display: flex;
  border: none;
  padding-top: 4px;
}
legend {
  /* margin: 0 auto;
  color: #447b91; */
}
fieldset {
  position: absolute;
  width: 100%;
  border: 1px solid #447b91;
  border-radius: 4px;
}
.cont-item {
  width: 20%;
}
.cont-item form {
  display: flex;
  flex-wrap: nowrap;
}
.c2 {
  gap: 15px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow: scroll;
  height: 50%;
  padding: 0 20px;
  
}
.c3 {
  justify-content: center;
  align-items: end;
  text-align: center;
}
.title h3 {
  text-transform: uppercase;
}
th {
  text-transform: uppercase;
}
.c4 {
  gap: 15px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow: auto;
  height: 80%;
  padding: 0 20px;
  justify-content: center;
}
.ci2 {
  width: 80%;
}
label {
  white-space: pre;
}
.addItem {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #570000;
  border: 0;
  cursor: pointer;
}
tfoot {
  position: relative;
}
tfoot tr {
  /*position: absolute;
  left: -20px;*/
}
tfoot td {
  /*position: fixed;
  z-index: 9;
  bottom: 0;
  width: auto;*/
}

i {
  cursor: pointer;
}
input.firm-input {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-radius: 0 !important;
  max-width: 70%;
}
input[type="checkbox"] {
  width: auto;
}
.tfooter {
  position: relative;
  transform: translateY(-90px);
  z-index: 100;
  background: #f2f2f2;
  overflow-x: scroll;
  scrollbar-position: start;
}

.container-checkbox {
  display: flex;
  flex-direction: column;
}
.container-checkbox label {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 7px;
  width: 100%;
}
.pagination i {
  margin-bottom: -4px;
}
.filter-item-input input::-webkit-calendar-picker-indicator {
  display: none !important;
}
/*
table.table-totals tr:hover {
  background: unset !important;
}
*/

.tr-h:hover {
  background-color: rgb(0 65 155 / 28%) !important
}

.td-nh {
  background: #002353;
  /* background: linear-gradient(#002353, #00060e); */
  color: #fff;
}
.mb-5 {
  margin-bottom: 50px;
}
.tr-hidden * {
  border-color: transparent !important;
  transition: all 1s;
}
.tr-hd {
  opacity: 0;
  border-color: transparent;
  transition: all 1s;
}
.required {
  color: red;
}
.th-title {
  display: flex;
  justify-content: center;
  gap: 5px;
  position: relative;
  white-space: nowrap;
}
.actions *{
  cursor: pointer !important;
}
.hidden{
  color: transparent;
}